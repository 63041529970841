.item {
    align-items: center;
    color: #FFF;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    width: 40px;
    border: solid 1px #808e9b;

  
  }
  
  .disabled-page {
    color: #808e9b;
  }
  
  .active {

    background: rgb(59 130 246);
    outline: none;
    color: white;
    padding: 10px 16px;
    text-align: center;
    align-items: center;
    border: solid 1px rgb(59 130 246);
  }
  
  .break-me {
  }
  
  .next {
    border-left: solid 1px #808e9b;
    font-size: 4px;
    height: 60px;
    position: absolute;
    right: 0;
    width: 150px;
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .pagination {
    align-items: center;
  
    display: flex;
    flex-direction: row;
  
    justify-content: center;
    list-style-type: none;
    position: relative;
    width: fit-content;

    /* border-width: 1px; */
    border-top: 1px solid #D1D5DB;
    border-bottom: 1px solid #D1D5DB;
    border-left: 1px solid #D1D5DB;
    font-size: 14px;
    height: 43px;
   
  
    /* border-radius: 0.5rem; */
  }
  
  .pagination-page {
    font-weight: 700;
  }


  .pagination .page-item {
    /* border-width: 1px;
    border-color: #D1D5DB; */
    border-right: 1px solid #D1D5DB;
    padding: 10px 16px;
    height: 43px;
  }


  
  .previous {
    border-right: solid 1px #808e9b;
    font-size: 4px;
    height: 60px;
    left: 0;
    position: absolute;
    width: 150px;
    padding-left: 16px;
    padding-right: 16px;
  }
  