@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "desktop-navigation.css";
@import "pagination.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import "datepicker.css";

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.horizontal-line {
  align-self: center;
  width: 99%;
  height: 1px;
  background-color: #dddddd;
  margin: 0px 5px 0px 5px;
}

.custom-styles {
  --ReactInputVerificationCode-itemWidth: 2.5rem;
  display: flex;
  justify-content: center;
  width: 100%;
  --ReactInputVerificationCode-itemHeight: 2.5rem;
}
:root {
  --spinner-size: 16px;
  --spinner-border-width: 2px;
  --spinner-color: #fff;
  --spinner-empty-color: transparent;
  --spinner-speed: 1s;
  --photo-spinner-size: 24px;
  --big-photo-spinner-size: 36px;
  --photo-spinner-border-width: 3px;
  --photo-spinner-color: #3b82f6;
}
.loading-spinner {
  margin: 2px;
  width: var(--spinner-size);
  height: var(--spinner-size);
  border: var(--spinner-border-width) solid var(--spinner-color);
  border-top: var(--spinner-border-width) solid var(--spinner-empty-color);
  border-radius: 50%;
  animation: spin var(--spinner-speed) linear infinite;
}
.loading-spinner.blue-spinner {
  --spinner-color: #1e3a8a;
  --spinner-empty-color: transparent;
}
.loading-spinner.big-spinner {
  --spinner-size: 20px;
}
.white.photo-upload-spinner {
  --photo-spinner-color: white;
}
.photo-upload-spinner {
  margin: 2px;
  width: var(--photo-spinner-size);
  height: var(--photo-spinner-size);
  border: var(--photo-spinner-border-width) solid var(--photo-spinner-color);
  border-top: var(--photo-spinner-border-width) solid var(--spinner-empty-color);
  border-radius: 50%;
  animation: spin var(--spinner-speed) linear infinite;
}
.photo-upload-spinner.big-spinner {
  margin: 2px;
  width: var(--big-photo-spinner-size);
  height: var(--big-photo-spinner-size);
  border: var(--photo-spinner-border-width) solid var(--photo-spinner-color);
  border-top: var(--photo-spinner-border-width) solid var(--spinner-empty-color);
  border-radius: 50%;
  animation: spin var(--spinner-speed) linear infinite;
}
.google-spinner {
  margin: 2px;
  width: 60px;
  height: 60px;
  border: 4px solid #2563eb;
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin var(--spinner-speed) linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login-facebook {
  font-family: Lucida Grande, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: inline-block;
  font-size: 16px;
  padding: 13px 30px;
  background: #1877f2;
  color: #fff;
  text-decoration: none;
  line-height: 1;
  position: relative;
  border-radius: 5px;
}

.z-100-important {
  z-index: 100 !important;
}

.top-shadow {
  box-shadow: 0 -1px 3px -1px rgb(0 0 0 / 0.1);
}

.quill {
  display: flex;
  flex-direction: column;
  border-radius: 0px;
}
.ql-editor {
  min-height: 500px;
  border-radius: 0px !important;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  color: #111827;
  letter-spacing: initial !important;
}
.ql-editor p {
  margin: 18px 0px !important;
}
.ql-editor p:first-of-type {
  margin: 0px 0px 18px !important;
}
.ql-container {
  border-radius: 0px;
  border: 1px solid rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  border-top: 0px !important;
  border-radius: 0rem 0rem 0.25rem 0.25rem !important;
}
.ql-toolbar {
  border: 1px solid rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  border-radius: 0.25rem 0.25rem 0rem 0rem !important;
}
.screen-height-minus-navbar {
  height: calc(100vh - 150px);
}
.min-screen-height-minus-navbar {
  min-height: calc(100vh - 150px);
}

.tap-highlight-transparent {
  -webkit-tap-highlight-color: transparent;
}

@media (min-width: 640px) {
  .sm\:mt-18px {
    margin-top: 18px;
  }
}

@media (max-width: 639px) {
  .textarea-min-height {
    min-height: 200px !important;
    height: 100% !important;
  }
}

.blog-header > h1 {
  font-size: 32px;
  line-height: initial;
}
.blog-header > h2 {
  font-size: 24px;
  line-height: initial;
}
.blog-header > h3 {
  font-size: 20px;
  line-height: initial;
}

@layer components {
  .text-xs-normal {
    @apply text-xs font-normal;
  }
  .text-xs-medium {
    @apply text-xs font-medium;
  }
  .text-xs-semibold {
    @apply text-xs font-semibold;
  }
  .text-xs-bold {
    @apply text-xs font-bold;
  }
  .text-sm-normal {
    @apply text-sm font-normal;
  }
  .text-sm-medium {
    @apply text-sm font-medium;
  }
  .text-sm-semibold {
    @apply text-sm font-semibold;
  }
  .text-sm-bold {
    @apply text-sm font-bold;
  }
  .text-body-normal {
    @apply text-base font-normal;
  }
  .text-body-medium {
    @apply text-base font-medium;
  }
  .text-body-semibold {
    @apply text-base font-semibold;
  }
  .text-body-bold {
    @apply text-base font-bold;
  }
  .text-h3-normal {
    @apply text-lg font-normal;
  }
  .text-h3-medium {
    @apply text-lg font-medium;
  }
  .text-h3-semibold {
    @apply text-lg font-semibold;
  }
  .text-h3-bold {
    @apply text-lg font-bold;
  }
  .text-h2-normal {
    @apply text-xl font-normal;
  }
  .text-h2-medium {
    @apply text-xl font-medium;
  }
  .text-h2-semibold {
    @apply text-xl font-semibold;
  }
  .text-h2-bold {
    @apply text-xl font-bold;
  }
  .text-h1-normal {
    @apply text-2xl font-normal;
  }
  .text-h1-medium {
    @apply text-2xl font-medium;
  }
  .text-h1-semibold {
    @apply text-2xl font-semibold;
  }
  .text-h1-bold {
    @apply text-2xl font-bold;
  }
  .text-display-normal {
    @apply text-3xl font-normal;
  }
  .text-display-medium {
    @apply text-3xl font-medium;
  }
  .text-display-semibold {
    @apply text-3xl font-semibold;
  }
  .text-display-bold {
    @apply text-3xl font-bold;
  }
  @media (min-width: 640px) {
    .sm\:text-xs-normal {
      @apply text-xs font-normal;
    }
    .sm\:text-xs-medium {
      @apply text-xs font-medium;
    }
    .sm\:text-xs-semibold {
      @apply text-xs font-semibold;
    }
    .sm\:text-xs-bold {
      @apply text-xs font-bold;
    }
    .sm\:text-sm-normal {
      @apply text-sm font-normal;
    }
    .sm\:text-sm-medium {
      @apply text-sm font-medium;
    }
    .sm\:text-sm-semibold {
      @apply text-sm font-semibold;
    }
    .sm\:text-sm-bold {
      @apply text-sm font-bold;
    }
    .sm\:text-body-normal {
      @apply text-base font-normal;
    }
    .sm\:text-body-medium {
      @apply text-body-medium;
    }
    .sm\:text-body-semibold {
      @apply text-base font-semibold;
    }
    .sm\:text-body-bold {
      @apply text-base font-bold;
    }
    .sm\:text-h3-normal {
      @apply text-lg font-normal;
    }
    .sm\:text-h3-medium {
      @apply text-lg font-medium;
    }
    .sm\:text-h3-semibold {
      @apply text-lg font-semibold;
    }
    .sm\:text-h3-bold {
      @apply text-lg font-bold;
    }
    .sm\:text-h2-normal {
      @apply text-xl font-normal;
    }
    .sm\:text-h2-medium {
      @apply text-xl font-medium;
    }
    .sm\:text-h2-semibold {
      @apply text-xl font-semibold;
    }
    .sm\:text-h2-bold {
      @apply text-xl font-bold;
    }
    .sm\:text-h1-normal {
      @apply text-2xl font-normal;
    }
    .sm\:text-h1-medium {
      @apply text-2xl font-medium;
    }
    .sm\:text-h1-semibold {
      @apply text-2xl font-semibold;
    }
    .sm\:text-h1-bold {
      @apply text-2xl font-bold;
    }
    .sm\:text-display-normal {
      @apply text-3xl font-normal;
    }
    .sm\:text-display-medium {
      @apply text-3xl font-medium;
    }
    .sm\:text-display-semibold {
      @apply text-3xl font-semibold;
    }
    .sm\:text-display-bold {
      @apply text-3xl font-bold;
    }
  }
}

.pagination {
  border-radius: 0.5rem;
}
.page-item {
  padding: 0px;
  cursor: pointer;
}
.pagination .page-item {
  padding: 0px;
}
.page-item:last-of-type {
  border-radius: 0.5rem;
}
.page-link {
  display: flex;
  font-weight: 700;
  height: 43px;
  width: 43px;
  justify-content: center;
  align-items: center;
}
.page-item.active {
  color: #ffffff;
  border: none;
}
.page-item.disabled {
  color: #111827;
}

#my-tooltip {
  background-color: #ffffff;
  color: #6b7280;
  pointer-events: none;
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  border-radius: 0.5rem; /* 8px */
  width: 250px;
  opacity: 100%;
  filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.05))
    drop-shadow(0 8px 5px rgb(0 0 0 / 0.1));
  padding: 1rem; /* 16px */
  white-space: pre-wrap;
}
.triangle-up {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #2563eb;
}
.triangle-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #2563eb;
}
.triangle-left {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #2563eb;
}
.triangle-right {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #2563eb;
}
.ReactInputVerificationCode__container > div {
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  border: 1px solid rgb(209 213 219);
}

.container {
  display: flex;
  height: 100%;
  width: 100%;
}

.dropzone {
  flex: 1;
  height: 100%;
}

.grid-item {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.parallelogram {
  transform: skewX(-20deg);
}
.parallelogram p {
  transform: skewX(20deg);
}

.draggable-item {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.shadow-all-sides {
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
}

@media (max-width: 480px) {
  .Toastify__toast-container--top-center {
    z-index: 999 !important;
    top: 20px !important;
    left: 8px !important;
    right: 8px !important;
    margin: 0px auto !important;
    width: 95vw !important;
  }
}
.Toastify__close-button {
  align-self: auto !important;
}

.animate__slideInLeft,
.animate__slideOutLeft,
.animate__slideInTop,
.animate__slideOutTop {
  --animate-duration: 0.4s;

  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animate__slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}
.animate__slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}
.animate__slideInTop {
  -webkit-animation-name: slideInTop;
  animation-name: slideInTop;
}
.animate__slideOutTop {
  -webkit-animation-name: slideOutTop;
  animation-name: slideOutTop;
}

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideOutLeft {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@-webkit-keyframes slideInTop {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideInTop {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slideOutTop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
@keyframes slideOutTop {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0%, 0);
    transform: translate3d(-100%, 0%, 0);
  }
}

input.custom-knob::-webkit-slider-thumb {
  width: 25px;
  height: 25px;

  border: 4px solid white;

  border-radius: 50%;

  background-color: #3b82f6;

  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);

  -webkit-appearance: none;
}

.MuiTooltip-tooltipPlacementBottom {
  margin-top: 10px !important;
}
.MuiTooltip-tooltip {
  padding: 4px 14px !important;
  background-color: #374151 !important;
  font-size: 13px !important;
}
.MuiTooltip-arrow {
  color: #374151 !important;
}
.MuiSlider-root {
  padding: 0px !important;
}

.slider {
  display: flex;
  transition: transform 0.5s;
}

#edit-photo-container {
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.advanced-cropper {
  background: black !important;
}

[placeholder] {
  text-overflow: ellipsis;
}

body {
  overscroll-behavior-y: none;
}

@layer components {
  .ligth-placeholder::placeholder {
    @apply text-gray-500 text-sm font-normal leading-tight;
  }
}

.react-color-custom .react-colorful {
  border-radius: 12px;
  width: 225px !important;
}

.react-color-custom .react-colorful__saturation {
  border-radius: 5px;
}

.react-color-custom .react-colorful__hue,
.react-color-custom .react-colorful__alpha {
  height: 14px;
  border-radius: 5px;
  margin-top: 16px;
}

.react-color-custom .react-colorful__hue-pointer,
.react-color-custom .react-colorful__alpha-pointer {
  width: 20px;
  height: 20px;
}

.star-ratings {
  display: flex !important;
  align-items: center !important;
}

.day-month-picker .react-datepicker__day-names {
  display: none;
}

.day-month-picker .react-datepicker__view-calendar-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}