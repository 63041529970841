.slide-enter {
  opacity: 0.01;
}

.slide-enter.slide-enter-active {
  opacity: 1;
  transition: opacity 10000ms ease-in;
}

.slide-leave {
  opacity: 1;
}

.slide-leave.slide-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
