.heroicon-stroke-w-0\.4 > path {
  stroke-width: 0.4;
}
.heroicon-stroke-w-0\.8 > path {
  stroke-width: 0.8;
}
.heroicon-stroke-w-1 > path {
  stroke-width: 1;
}
.heroicon-stroke-w-1\.2 > path {
  stroke-width: 1.2;
}
.heroicon-stroke-w-1\.6 > path {
  stroke-width: 1.6;
}
