/* datepicker.css */

.react-datepicker__month-container {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
}

.react-datepicker__input-container {
  display: inline-block;
  width: 100%;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  width: 100%;
}

.react-datepicker__input-container > input {
  padding: 9px 13px;
}

.react-datepicker {
  border: 1px solid #d1d5db !important;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.react-datepicker__header {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: none !important;
  background-color: white !important;
  padding-bottom: 0px !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name {
  color: #1f2937;
}

.react-datepicker__day--outside-month {
  color: #d1d5db;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #3b82f6;
  color: white;
}

.react-datepicker__day:hover {
  background-color: #e5e7eb;
}

.react-datepicker__month-text--keyboard-selected {
  background-color: #e5e7eb !important;
}

.react-datepicker__navigation-icon {
  top: 5px !important;
}

.react-datepicker__month .react-datepicker__month-text {
  padding: 6px !important;
  margin: 2px !important;
}
